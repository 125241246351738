@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-notifications/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply m-0 leading-[normal];
}

*,
::before,
::after {
  border-width: 0;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

.title-text {
  @apply text-left text-[#193861];
  font-size: 15px;
}

.e-dialog {
  width: 30% !important;
  max-width: 90vw !important;
}

.e-dialog .e-dlg-content {
  width: 100% !important;
}

.e-dlg-overlay {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Styles for disabled MultiSelect */
.disabled-multiselect.e-multiselect {
  background-color: #f3f4f6 !important;
}

.disabled-multiselect .e-multi-select-wrapper {
  background-color: #f3f4f6 !important;
}

.disabled-multiselect .e-delim-values {
  color: #a0a0a0 !important;
}

.disabled-multiselect .e-input-group-icon.e-ddl-icon {
  color: #a0a0a0 !important;
}

/* Ensure the chip background is also gray when disabled */
.disabled-multiselect .e-multi-select-wrapper .e-chips {
  background-color: #e5e7eb !important;
  color: #a0a0a0 !important;
}

/* Optional: Style the chip close button if needed */
.disabled-multiselect .e-multi-select-wrapper .e-chips .e-chips-close::before {
  color: #a0a0a0 !important;
}

/* Add this to your global CSS file or as a styled component */
.form-radio {
  position: relative;
}

.form-radio::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #4b5563;
  border-radius: 50%;
}

.form-radio:checked::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2563eb;
}

.e-headercelldiv {
  margin-right: 1px !important;
  margin-left: 1px !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  max-width: 90vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.close-button svg {
  width: 24px;
  height: 24px;
  color: #4b5563;
}

.image-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  padding: 24px;
  min-height: 300px;
}

.image-container img {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.no-image {
  font-size: 18px;
  color: #6b7280;
}

.image-info {
  background-color: #ffffff;
  padding: 16px 24px;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-info h2 {
  margin: 0;
  font-size: 18px;
  color: #1f2937;
  font-weight: 600;
  text-align: center;
}

.close-btn {
  background-color: #3b82f6;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: #2563eb;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 16px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-button.left {
  left: 10px;
}

.carousel-button.right {
  right: 10px;
}

.message-dialog.e-dialog {
  max-height: 100vh !important;
  width: 200vh !important;
  display: flex;
  flex-direction: column;
}

.message-dialog .e-dlg-content::-webkit-scrollbar {
  width: 8px;
}

.message-dialog .e-dlg-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.message-dialog .e-dlg-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.message-dialog .e-dlg-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.new-grid-color .e-gridheader .e-headercell {
  background-color: #fff3ce !important;
  color: #000000 !important;
  font-weight: bold;
}

.new-grid-color-header-cell .e-detailheadercell {
  background-color: #fff3ce !important;
}

.grid-noheader > .e-gridheader {
  display: none; /* Hides the main grid's header */
}

.grid-noheader .e-gridfooter,
.e-summarycell {
  background-color: transparent;
}

.zoomed-image {
  width: 100%;
  height: 100%;
  transform: scale(1.5); /* Increased from 1.2 to 1.5 for bigger zoom */
  transition: transform 0.3s ease-in-out;
  object-fit: cover !important; /* Forces image to cover the space */
  min-width: 120%; /* Makes sure image is always larger than container */
  min-height: 120%;
  position: absolute; /* Allows overflow positioning */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.5); /* Centers the image and applies zoom */
}

.e-pager .e-numericitem {
  font-size: 14px;
  font-weight: semibold;
  border-radius: 2px;
  border: none;
  transition: all 0.3s ease;
}

.e-pager .e-currentitem {
  background-color: #003460 !important;
  color: #ffc72c !important;
  border: none;
  font-weight: bold;
}

.e-pager .e-numericitem:hover {
  background-color: #002244;
}
